@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.ttf") format("truetype"),
    url("../fonts/Poppins-Regular.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.ttf") format("truetype"),
    url("../fonts/Poppins-Bold.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.ttf") format("truetype"),
    url("../fonts/Poppins-Medium.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.ttf") format("truetype"),
    url("../fonts/Poppins-Light.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("../fonts/Poppins-ExtraLight.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLight.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}
