@import "../../assets//variables/variables";

.navlink {
  text-decoration: none;
  transition: 0.2s;
  position: relative;

  &::after,
  &::before {
    position: absolute;
    height: 100%;
    color: #000;
    opacity: 0;
    transition: 0.3s $superSmooth;
  }

  &::after {
    left: 5px;
    content: "[";
  }

  &::before {
    content: "]";
    right: 5px;
  }

  &:hover {
    color: $primary-light-color;

    &::before,
    &::after {
      opacity: 1;
    }

    &::before {
      right: 0;
    }

    &::after {
      left: 0;
    }
  }

  &.active {
    &::before,
    &::after {
      opacity: 1;
    }

    &::before {
      right: 0;
    }

    &::after {
      left: 0;
    }
  }
}
