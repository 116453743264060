@import "poly-fluid-sizing";

// Colors

$primary-dark-color: #e69100;
$primary-color: #ffa915;
$primary-medium-color: #ffc766;
$primary-light-color: #ffda99;
$primary-superLight-color: #ffeccc;
$tag-olor: #7f85a8;

$color-grey: #878686;
$color-grey-medium: #bdbaba;
$color-grey-light: #f0f2f2;

$color-purple: #3b4a99;
$color-purple-medium: #7282d6;
$color-purple-light: #bdc7ff;

$color-green: #3b6f78;
$color-green-medium: #6ea5ae;
$color-green-light: #a5e1eb;

$color-orange: #d1735e;
$color-orange-medium: #e79684;
$color-orange-light: #ffc4b7;

$color-cyan: #07a1e2;
$color-cyan-medium: #74cbf0;
$color-cyan-light: #a8e5ff;

$color-yellow: #e8c422;
$color-yellow-medium: #f2d864;
$color-yellow-light: #faeba6;

$color-error: #ff0000;
// Vertical Sizes

$size-tiny: 20px;
$size-small: 40px;
$size-medium: 60px;
$size-big: 80px;
$size-large: 100px;
$size-extra-large: 140px;

//  Formats

$container-float-half: calc((100vw - 1310px) / 2);
$container-float-half-large: calc((100vw - 1170px) / 2);
$container-float-half-mid: calc((100vw - 960px) / 2);
$container-float-half-small: calc((100vw - 720px) / 2);

// Breakpoints

$extraSmall: 567px;
$small: 768px;
$medium: 992px;
$large: 1200px;

// Transitions

$superSmooth: cubic-bezier(0.25, 1, 0.5, 1);
$linearSmooth: cubic-bezier(0, 0.55, 0.45, 1);
